// Mixins
%transition-all {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}

@mixin slim-navbar-brand() {
  .navbar-header {
    margin-left: 0.5em !important;
  }
  .navbar-brand {
    background: url('../svg/SKM-small.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 52px;
    min-height: 52px;
    margin: 0.3em 0;
    font-size: 0.5em;
  }
  img.site-logo {
    display: none;
  }
}

@mixin regular-navbar-brand() {
  .navbar-brand {
    background: none;
    width: 244px;
    margin: 0.5em 0;
    font-size: 0.75em;
  }
  img.site-logo {
    display: block;
    height: 48px;
  }
}

@mixin build-responsive-page($m) {
  $top-bottom-padding: .7rem;
  $left-right-padding: 1.25rem;

  section {
    padding: $m*1.5em 0;
  }

  .close-button {
    top: $m * 1.5em;
    right: $m * 2em;
  }

  .client-portfolio {
    .header {
      padding: $m * 2em 0;
      height: $m * 20em;

      .title {
        position: relative;
        top: $m * 22rem;
      }
    }
  }

  .video-clip {
    min-height: $m * 540px;
  }

  // Services page
  .service-caption {
    min-height: $m * 60px;
  }

  .navbar {
    padding: $m*.5rem 0 $m*.1rem 0;
    .navbar-brand {
      padding: $m * .5em 0 !important;
      // margin: $m*0.5em $m*0.5em;
    }
    .navbar-nav {
      >li {
        > .navbar-link {
          padding: $m*.35em 1em;
          margin: $m*1.5em 0;
          font-size: $m*.8em;
          letter-spacing: $m*0.1em;
        }
      }
    }
  }

  // HORIZONTAL RULE
  hr {
      margin: $m * 1rem auto;
  }

  // Specific classes
  .modal-header {
    height: $m * 42%;
  }
  .timeline img {
    width: $m * 240px;
    height: $m * 240px
  }

  body {
    font-size: 1.5em * $m;
    p {
      line-height: $base-line-height * $m;

      &.lead {
        font-size: 1.1em * $m;
        line-height: $base-line-height * $m;

      }
    }
  }

  hr.spacer {
      margin: $m*0.25em auto;
  }
  h1 {
    font-size: 2.75em * $m;
    margin: $m*0.5rem 0;
  }
  h2 {
    font-size: 2.3em * $m;
    margin: $m*2.25rem 0 $m*.5rem 0;
  }
  h3 {
    font-size: 1.6em * $m;
    margin: $m*1rem 0 $m*.25rem 0;
  }
  h4 {
    font-size: 1.45em * $m;
    margin: $m*1.5rem 0 $m*.5rem 0;
  }
  h5 {
    font-size: 1.3em * $m;
    margin: $m*1rem 0 $m*.25rem 0;
  }
  h6 {
    font-size: 1em * $m;
    margin: $m*10px 0;
  }

  // No space above sutitle to keep compact

  // Add space between columns
  *[class^='col-sm'] {
      padding: 0 $m*30px;
  }
  .btn {
    padding: $m*.5em $m*1.25em $m*.35em $m*1.25em ;
  }
  .btn-md {
    font-size: $m*0.75em;
  }
  .btn-md {
    font-size: $m*1em;
  }
  .btn-lg {
    font-size: $m*1.25em;
  }
  .btn-xl {
    font-size: $m*1.5em;
  }

  .hero {
    font-size: $m*2.6em;
    margin-top: $m * 6%;
    line-height: $m*1.4em;
    font-weight: 400;
    margin-bottom: auto;
    padding: 0 $m * .85em 0 0;
  }
}

// Mobile
@media (min-width: 0px) and (max-width: 519px) {
  @include slim-navbar-brand();
  // ul.nav { border: 1px solid black; }

  .sss {
    .ssslide {
      .caption {
        padding: 2rem 6rem;
      }
    }
    .sssnext, .sssprev {
      width: 4rem !important;
    }
  }
}

// Laptop
@media (min-width: 520px) and (max-width: 767px) {
  @include slim-navbar-brand();
  // ul.nav { border: 1px solid purple; }

  // .sss {
  //   min-height: 50rem;
  // }
}

@media (min-width: 768px) and (max-width: 991px) {
  @include slim-navbar-brand();
  // ul.nav { border: 1px solid red; }
}

@media (min-width: 992px) and (max-width: 1600px) {
  @include regular-navbar-brand();
  // ul.nav { border: 1px solid green; }
}

@media (min-width: 1601px) {
  @include regular-navbar-brand();
  // ul.nav { border: 1px solid white; }
}

