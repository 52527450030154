// Partials
@import "variables";
@import "fonts";
@import "mixins";
@import "base";
@import "components";
@import "team";
@import "clients";
@import "navbar";
@import "timeline";
@import "footer";

@charset "utf-8";

div.row {
  // border: 1px solid red !important;
}

div[class^="col-"] {
  // border: 1px solid blue !important;
}
