
$col-width: 420px;
$close-button-size: 40px;
$close-button-parent: 54px;

.client-portfolio {
  position: relative;
  top: 0;
  // border: 1px solid blue;

  .header {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-gray-darkest;

    h1.title {
      position: absolute;
      letter-spacing: 0.025em;
      font-weight: 700;
      text-transform: none;
      color: #fff;
      text-shadow: 0.15rem 0.15rem 0.15rem rgba(0,0,0,0.5);
    }
  }
}

.close-button {
  opacity: 1;
  padding: 0;
  width: 3.5em;
  height: 3.5em;
  position: fixed;
  border-radius: 4px;
  background-color: darken($color-tertiary, 4%);
  border: 1px solid darken($color-tertiary, 12);
  cursor: pointer;
  z-index: 1000;
  // background-color: rgba(250, 80, 140, 1);
  transition: all ease .3s;

  &:hover {
    background-color: $color-tertiary;
  }
}

.portfolio-modal {
  z-index: 9999;
  background-color: #fff;

  img {
    margin-bottom: 1em;
  }

  .modal-content {
    min-height: 100%;
    height: 100%;
    border: 0;
    border-radius: 0;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;

    .modal-header {
      height: 20%;
      padding: 1em 1em;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: $color-gray-darkest;

      .title {
        position: relative;
        width: 100%;
        height: 100%;

        h1 {
          position: absolute;
          letter-spacing: 0.025em;
          bottom: .25em;
          left: .3em;
          font-weight: 800;
          text-transform: none;
          color: #fff;
          text-shadow: 0.15rem 0.15rem 0.15rem rgba(0,0,0,0.5);
        }
      }
    }
  }


}

#portfolio {
  .portfolio-item {
    right: 0;
    margin: 0 0 1.5em;
    padding: .4em;

    img {
      width: $col-width;
    }
    .portfolio-caption {
      color: #fff;
      margin: 0 auto;
      padding: .5em 0 .8em 0;
      max-width: $col-width;
      text-align: center;
      background-color: darken($color-tertiary, 10);
      border-top: 1px solid #000;

      p {
        margin-bottom: 0;
      }
    }

    .portfolio-link {
      display: block;
      position: relative;
      margin: 0 auto;
      max-width: $col-width;

      .portfolio-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        -webkit-transition: all ease .5s;
        -moz-transition: all ease .5s;
        transition: all ease .5s;
        background: rgba(250,250,250,.6);

        .portfolio-hover-content {
          position: absolute;
          top: 50%;
          width: 100%;
          margin-top: -1em;
          text-align: center;
          font-size: 24px;
          color: $color-tertiary;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
