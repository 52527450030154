.team-member-detail {
  h1 {
    font-size: 2.4em;
  }
  h2 {
    font-size: 2em;
  }
  .close-button {
    // In case we need overrides here
  }
}

