
.sidebar-sticky {
  bottom: 1rem;
}

ul.social-media-list {
  border-top: 1px solid $color-gray-darker;
  opacity: 0.9;
  margin: 1.5rem 2rem 1rem 0;
  padding-top: .75rem;

  > li {
    list-style: none;
    margin-left: 0;
    line-height: 1.75rem;
    a {
      font-size: 0.75rem;
      span.username {
        color: $color-gray;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// Icons
.svg-icon {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  fill: #{$color-gray};
  margin-top: 0rem;
  margin-right: 0.3rem;
  vertical-align: text-top;
}


//
// Navigation bar
//
.navbar {
  border-top: 4px solid $color-tertiary;
  font-family: $body-font-family;
  transition: all 0.2s ease;

  @media screen  and (max-width: 767px) {
    .navbar-collapse {
      border: 0;
      transition: all 0.35s;
      background: -webkit-gradient(linear, left top, left bottom,
        from(rgba(73,169,126,1)),
          to(rgba(73,169,126,1)), color-stop(0, rgba(80,80,80,1))
      );
      // background-color: rgba(73,169,126,1);
    }
  }
  @media screen and (min-width: 768px) {
    .navbar-collapse {
      border-bottom-left-radius: none;
      border-bottom-right-radius: none;
    }
  }

  .navbar-toggle {
    height: 40px;
    width: 40px;
    border: 0;
    padding: 10px 0;
    border-radius: 4px;
    transition: all 0.3s ease;
    cursor: pointer;
    background: $color-red-bright;

    i {
      font-size: 1.6em !important;
    }

    &:hover {
      background: lighten($color-red-bright,15);
      .icon-bar {
        background: $color-gray-lightest;
      }
    }
    }

  &.navbar-default {
    background-color: rgba(30, 30, 30, .45);
  }

  // BG COLOR UPON SCROLL
  &.navbar-shrink {
    // background: darken($color-green, 38) !important;
    background-color: darken($color-gray-darker, 10);
    border-color: transparent;
  }

  .navbar-brand {
    font-family: $header-font-family-alt;
    color: $color-secondary;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    text-shadow: 0.05rem 0.05rem 0.05rem rgba(0, 0, 0, 0.5);

    @media (min-width: 768px) {
      text-shadow: 0.05rem 0.05rem 0.15rem rgba(0, 0, 0, 0.95);
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: lighten($color-secondary, 20);
    }
  }

  .navbar-nav {
    text-align: left;

    >.active>a {
      border-radius: 40px;
      color: #fff;
      background-color: $color-tertiary;
    }

    >.active>a:hover,
    >.active>a:focus {
        color: #fff;
        background-color: $color-tertiary;
    }

    >li {
      list-style: none;
      margin-left: 0 !important;

      > .navbar-link {
        text-shadow: 0.05rem 0.05rem 0.05rem rgba(0, 0, 0, 0.85);
        color: $color-gray-lightest;
        font-family: $header-font-family;
        font-weight: 700;
        text-transform: uppercase;

        &:hover {
          transition: all 0.4s ease;
          color: lighten($color-primary, 60);
          text-decoration: none;
        }
      }
    }
  }

  img.site-logo {
    max-width: none;
  }

  .site-logo-sticky {
    cursor: pointer;
    position: fixed;
    z-index: 100;
    width: auto;
    height: 48px;
    right: 14px;
    bottom: 10px;
    opacity: 0;
    background-color: #fff;
    border: 1px solid $color-gray-lightest;
    box-shadow: 0.05rem 0.05rem 0.15rem rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;

    border-radius: 100px;
    padding: 0px;
    &:hover {
      background-color: $color-gray-lightest;
    }
  }
}

