@import url('https://fonts.googleapis.com/css?family=Niramit:300,700,900|Assistant:300,400,700|Biryani:200,300,400,700,800,900');
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,700,900|Assistant:300,400,700|Biryani:200,300,400,700,800,900');


//
// Fonts and related mixins
//

$header-font-family: 'Biryani', 'Helvetica Neue', Helvetica, sans-serif !default;
$header-font-family-alt: 'Niramit', 'Helvetica Neue', Helvetica, sans-serif !default;

$body-font-family: 'Niramit', Arial, sans-serif !default;

// Preset sizes
$h1FontSize: 28px;
$h2FontSize: 24px;
$h3FontSize: 22px;
$h4FontSize: 20px;
$h5FontSize: 18px;
$h6FontSize: 16px;
$font-size-base: 14px;

$base-font-weight: 400 !default;
$small-font-size:  $font-size-base * 0.875 !default;
$base-line-height: 1.75 !default;

$text-color: $color-gray-dark;

@mixin relative-font-size($ratio) {
  font-size: $font-size-base * $ratio;
}

body {
  font-family: $body-font-family;
  font-weight: 200;
  color: $color-gray-darkest;

  p {
    &.muted {
      opacity: .9;
    }

    &.lead {
      font-weight: 300;
      padding: .5em 2em;
    }
  }
}
// TODO: copy responsive mixin
h1 {
  // font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  opacity: 1;
}
h2 {
  font-family: $header-font-family;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 1;

}

h1, h3, h4, h5, h6 {
  font-family: $header-font-family-alt;
}

h3, h4, h5, h6 {
  text-transform: none !important;
}


.text-primary {
  color: lighten($color-tertiary, 2);
}

.text-white {
  color:  #fff;
}

section h2.section-heading {
  margin: 2em 0;
}

section h3.section-subheading {
  margin-bottom: 75px;
  text-transform: none;
  font-size: 1.5em;
  font-weight: 200;
  letter-spacing: 0.015em;
}

header {
  font-family: $header-font-family;
}

a:not(.navbar-link):not(.navbar-brand):not(.btn) {
  color: $color-tertiary !important;
  font-weight: 500;
  &:visited {
    color: $color-tertiary !important;
  }
  &:hover {
  }
}
.post-meta {
  // color: lighten($color-primary, 30);
  color: lighten($color-gray, 10);
  letter-spacing: 2px;
}
.text-muted {
  color: #222;
}

// Headings

h1, h2, h3 {
  font-weight: 400;
  letter-spacing: -0.5px;
}
h4, h5, h6 {
  font-weight: 700;
}

// Set `margin-bottom` to maintain vertical rhythm
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit;
}

// figcaption {
//   font-size: $small-font-size;
// }

footer {
  color: $color-gray-light;
  font-size: .9em !important;
}
