//
// Footer
//

footer {
  position: relative;
  background-color: #fff;
  padding: 40px 0;
  text-align: center;

  .site-logo-footer {
    height: 40px;
    width: 186px;
    margin: -12px 0 -7px -3px;
  }

  .copyright {
    text-transform: uppercase;
    text-transform: none;
    line-height: 1em;
  }
  .quicklinks {
    margin-bottom: 0;
    text-transform: uppercase;
    text-transform: none;
    padding: .5em 0;
    font-size: 1em;
    li {
      line-height: 1em;
    }
  }

  .social-buttons li a {
    width: 30px;
    height: 30px;
    font-size: 1.2em !important;
    padding: 6px;
  }
}

