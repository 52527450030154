@import "variables";

//
// Base elements
//

body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

// Basic styling
body {
  background-color: lighten($color-gray-lightest, 10%);
  webkit-tap-highlight-color: $color-tertiary;
}

// Container Fluid, my way
.borderless {
  padding-left: -15px;
  padding-right: -15px;
}

// Set `margin-bottom` to maintain vertical rhythm
h1, h2, h3, h4, h5, h6,
p, blockquote, pre, dl, figure,
%vertical-rhythm {
margin-bottom: $spacing-unit * 2;
}

// `main` element
main {
  display: block;
}

hr {
  opacity: 0.8;
}

.spacer {
  opacity: 0;
  padding: 1.5rem 0;
}

// Images
img {
  max-width: 100%;
  vertical-align: middle;
}

// Figures
figure > img {
  display: block;
}

// Lists
ul, ol {
  margin-bottom: 0;
  li {
    margin-left: 1.5rem;
    line-height: 1.5rem;
  }
}

// Links
a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 15%);
  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  a {
    text-decoration: underline;
  }
}

.underline {
  text-decoration: underline;
}

// Blockquotes

blockquote {
  position: relative;
  margin-top: 4px;
  padding-left: 20px;
  border-width: 0 0 0 8px;
  border-style: solid;
  border-left: 2px solid $color-secondary;

  @include relative-font-size(1.125);
  letter-spacing: 0.5px;
  font-style: italic;

  // text-transform: uppercase;
  > :last-child {
    margin-bottom: 0;
  }
  &:before {
    position: absolute;
    opacity: 0.9;
    content: "\201C";
    color: lighten($color-primary, 12%);
    left: 0px;
    top: -4px;
    font-family: Georgia, serif;
    font-size: 28px;
  }
}

i.fa {
  padding: 0px 2px;
  width: auto;
  height: auto;
}

label {
  color: darken($color-gray,3%);
}

.container {
// padding-right: 20px;
// padding-left: 20px;

}
// Code formatting
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $color-gray-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.img-centered {
  margin: 0 auto;
}

.bg-light-gray {
  background-color: lighten($color-gray-lighter, 10);
}

.bg-light-green {
  background-color: lighten($color-secondary, 22);
}

.bg-light-blue {
  background-color: lighten($color-primary, 12);
}

// RESPONSIVENESSSSS
/* --------- 0-320px: iPhone 5 ---- */
@media screen and (max-width: 320px) {
  @include build-responsive-page(0.9);
  .video-clip {
    min-height: 120px !important;
  }
  // @include slim-navbar-brand();
}
/* --------- 321-546px: iPhone 6 / Plus --------------- */
@media screen and (min-width: 321px) and (max-width: 546px) {
  @include build-responsive-page(0.95);
  .video-clip {
    min-height: 220px !important;
  }
  // @include slim-navbar-brand();
}
/* --------- 547-767px -------------------------------- */
@media screen and (min-width: 547px)  and (max-width: 767px) {
  @include build-responsive-page(1.0);

  // @include slim-navbar-brand();
}
/* --------- 768px - 950px ---------------------------- */
@media screen and (min-width: 768px) and (max-width: 950px) {
  @include build-responsive-page(1.05);
}
/* --------- 950px - 1200px ----------------------------- */
@media screen and (min-width: 951px) and (max-width: 1200px) {
  @include build-responsive-page(1.1);
}
/* --------- 1201px - 1600px ------------------------------ */
@media screen and (min-width: 1201px) and (max-width: 1600px) {
  @include build-responsive-page(1.15);
}
/* --------- 1601px + ----------- */
@media screen and (min-width: 1601px) and (max-width: 10000px) {
  @include build-responsive-page(1.2);
}
